<template>
	<div class="pageContainer">
		<web-header></web-header>
		<div class="main">
			<router-title :menuList="menuList[0]" :list="menuList[1]" :list1="menuList[2]" />
		</div>
		<div class="content main">
			<div class="left">
				<div class="left-top">
					<div class="trainName">{{courseForm.name}}</div>
					<div><span class="trainTime">培训时间</span><span class="trainTimeDetail">{{courseForm.studyStartTime}}</span></div>
					<div v-if="courseForm.typeId == 1"><span class="trainTime">学时</span><span class="trainTimeDetail">{{courseForm.classHours}}</span></div>
					<div v-else><span class="trainTime">学分</span><span class="trainTimeDetail">{{courseForm.classHours}}</span></div>
					<div>
						<span class="trainCost">{{courseForm.cost ? `￥${courseForm.cost}` : '免费'}}</span>
						<div>
								<!-- <span class="tranpay" v-if="courseForm.isPay === 0" @click="clickHandle(courseForm)">立即购买</span>
							<span class="tranpay" v-else-if="courseForm.isPay === 1">已购买</span> -->
								<div   class="tranpay" @click="clickHandle(courseForm)"
									v-if="verifyCourseStatus(courseForm.studyEndTime,courseForm.isPay) === 1">立即购买</div>
								<div class="tranpay"
									v-if="verifyCourseStatus(courseForm.studyEndTime,courseForm.isPay) === 2">已购买</div>
								<div class="tranpay"
									v-if="verifyCourseStatus(courseForm.studyEndTime,courseForm.isPay) === 3">已结束</div>
					</div>
						
					</div>
				</div>
				<div class="left-bottom">
					<div class="trainHeader">
						<span class="trainHeaderItem" @click="haaderHander(1)" :class="headerIndex === 1? 'trainHeaderItemSelect':''">课程介绍</span>
						<span class="trainHeaderItem" @click="haaderHander(2)"  :class="headerIndex === 2? 'trainHeaderItemSelect':''">课程目录</span>
						<span class="trainHeaderItem"  @click="haaderHander(3)" :class="headerIndex === 3? 'trainHeaderItemSelect':''">课程评论</span>
					</div>
					<!-- 课程简介 -->
					<div class="trainJianjie" v-if="headerIndex === 1">{{courseForm.introduce}} </div>
					<!-- 课程目录 -->
					<div class="tablemulu" v-else-if="headerIndex === 2">
						<div class="tableHeader">
							<span class="time" >时长</span>
							<span class="curName">课程名称</span>
							<span class="curLianxi">课后练习</span>
							<span>观看时长</span>
						</div>
						<div class="tableBody">
							<div class="item" v-for="(it,i) in sectionList" :key="i">
									<div class="img" @click="toShiKan(it)"></div>
									<span class="time">
										<span>{{timeData(it.duration)}}</span>
									</span>
									<div class="name" @click="toShiKan(it)">
										<span>{{it.name}}</span>
									</div>
									<div class="type">
										<span v-if="!courseForm.isPay" @click="toShiKan(it)">试看</span>
									</div>
									<div class="typetime"	>
											<img  v-if="courseForm.isPay != 0 && it.studyTime >= 100" @click="handlePracticeClass(it)" src="../../assets/course/edit.png" alt="">
											<span v-else>无</span>
											</div>
									<div>
										{{it.studyTime}}%
									</div>
							</div>
						</div>
					</div>
					<!-- 课程评论 -->
					<div v-else-if="headerIndex ===3">
							<div class="contentThree">
									<span>留下你的评论</span>
										<el-rate class="pjia" v-model="commentValue" show-text>
										</el-rate>
								<div>
									<el-input type="textarea" placeholder="请输入内容" v-model="comment" maxlength="500" show-word-limit
										:rows="4">
									</el-input>
								</div>
								<div class="buttonCls" >
									<div class="btnpl" @click="commentEvt">评论</div>
								</div>
								<div class="allPing">全部评价({{commentList.length}})</div>
								<div class="pinglun" v-for="(it,i) in commentList" :key="i">
									<div class="touxiang">
										<img :src="it.photo" alt="">
									</div>
									<div style="margin-left:15px;flex:2;">
										<p>
											<span>{{it.stuName}}</span>
											<span style="pointer-events: none">
												<el-rate v-model="it.evaluation" :disabled="false">
												</el-rate>
											</span>
											<span class="timeClass">{{it.createTime}}</span>
										</p>
										<p>{{it.comment}}</p>
									</div>
								</div>
							</div>
					</div>
				</div>
			</div>
		</div>
		<web-footer></web-footer>
	</div>
</template>

<script>
	import WebFooter from '../../components/webFooter.vue'
	import webHeader from '../../components/webHeader.vue'
	import RouterTitle from "../../components/routerTitle.vue";
	export default {
		components: {
			webHeader,
			WebFooter,
			RouterTitle
		},
		data() {
			return {
				menuList: ["首页", "培训项目", "课程详情"],
				imgList: [{
						url: ''
					},
					{
						url: '1'
					},
					{
						url: '2'
					},
				],
				textarea: '',
				comment: '',
				value: 0,
				commentValue: 0,
				commentList: [],
				courseForm: {},
				sectionList: [],
				id: '',
				peixunxinde: '',
				// 获取章节视频的总共时间
				duration: '',
				orderId: '', //订单ID,
				headerIndex: 1,
			}
		},
		created() {
			this.id = this.$route.query.id
			this.orderId = this.$route.query.orderId
			const loading = this.$loading({
				lock: true,
				text: 'Loading',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			//获取单个课程信息
			this.$http.post('getCourseInfoById', {
				id: this.id
			}).then(res => {
				if (res.code === 200) {
					this.courseForm = res.data
					console.log(this.courseForm, 'this.courseForm ');
					loading.close()
					// console.log(this.courseForm,'------');
				} else {
					this.$message.error(res.message)
				}
			})
			//获取章节信息
			this.$http.post('getCourseSectionById', {
				id: this.id,
			}).then(res => {
				if (res.code === 200) {

					this.sectionList = res.data || []
				console.log(this.sectionList, 'this.sectionList');
				} else {
					this.$message.error(res.message)
				}
			})
			//获取课程评论
			this.commentListEvt()
		},
		methods: {
				// 培训课程校验 1 未购买 2 已购买 3已结束
			verifyCourseStatus(time, isPay) {
				let status = 0;
				if (Date.parse(time) < new Date()) {
					status = 3
				} else {
					if (isPay == 1) status = 2
					else status = 1
				}
				return status
			},
			haaderHander(val){
				this.headerIndex = val
			},
			// 时间转换
			timeData(time) {
				var hh;
				var mm;
				var ss;
				//传入的时间为空或小于0
				if (time == null || time < 0) {
					return;
				}
				//得到小时
				hh = time / 3600 | 0;
				time = parseInt(time) - hh * 3600;
				if (parseInt(hh) < 10) {
					hh = "0" + hh;
				}
				//得到分
				mm = time / 60 | 0;
				//得到秒
				ss = parseInt(time) - mm * 60;
				if (parseInt(mm) < 10) {
					mm = "0" + mm;
				}
				if (ss < 10) {
					ss = "0" + ss;
				}
				// if (hh == '00') return mm + ":" + ss
				// if (hh == '00' && mm == '00') return ss
				return hh + ":" + mm + ":" + ss;
			},

			//去试看页面
			toShiKan(it) {
				//判断是否支持试看
				this.$http.post('studyCourseApi', {
					courseId: this.id,
					sectionId: it.id,

				}).then(res => {
					if (res.code === 200) {
						//是否可以试看
						if (!res.data.id) {
							
							this.$message.success(`当前视频可观看${res.data.tryTime}秒`)
							setTimeout(() => {
								this.$router.push({
									path: '/trainingProgram/courseViewing',
									query: {
										id: this.id,
										sectionId: it.id,
										//试看
										status: this.courseForm.isPay,
										// 可以试看的时间
										time: res.data.tryTime,
										// 防挂机时间
										hangTime: this.courseForm.antiHook === 1 ? this.courseForm
											.hangTime : 0,
										data: it,
										// 数据
										listCourse: this.courseForm
									}
								})
							}, 1500)
						} else {
							this.$router.push({
								path: '/trainingProgram/courseViewing',
								query: {
									id: this.id,
									sectionId: it.id,
									status: this.courseForm.isPay,
									time: res.data.watchTheTime,
									// 防挂机时间
									hangTime: this.courseForm.antiHook === 1 ? this.courseForm.hangTime :
										0,
									data: it,
									// 数据
									listCourse: this.courseForm,
									orderId:this.courseForm.orderId,
								}
							})
						}
					} else {
						this.$message.error(res.message)
					}
				})
			},
			// 进入课后练习
			handlePracticeClass(e) {
				localStorage.setItem('zjieId', e.id)
				localStorage.setItem('coureId', e.courseId)
				this.$http.post('mineTest', {
					chapterId: e.id,
					courseId: e.courseId
				}).then(res => {
					if (res.code == 200) {
						this.$router.push({
							path: '/examination/afterClassTest'
						})
					} else {
						this.$message.warning(res.message)
					}
				})
			},
			// 立即购买
			clickHandle(e) {
				localStorage.setItem('cousreId', e.id)
				this.$router.push({
					path: '/trainingProgram/paymentOrder',
					query: {
						index: 3,
						type: 2,
						data: e
					}
				})
			},
			//发布培训心得
			peixunEvt() {
				this.$http.post('peixunAdd', {
					courseId: this.id,
					courseNotes: this.peixunxinde
				}).then(res => {
					const loading = this.$loading({
						lock: true,
						text: '发表心得中...',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					})
					if (res.code === 200) {
						this.peixunxinde = ''
						this.$message.success('发表心得成功')
					} else {
						this.$message.error(res.message)
					}
					loading.close()
				})
			},
			commentListEvt() {
				//获取课程评论
				this.$http.post('getCourseCommentById', {
					id: this.id
				}).then(res => {
					if (res.code === 200) {
						// console.log(res,'----1-----');
						this.commentList = res.data
						this.commentList.map(it => {
							it.disabledStatus = false
							return it
						})
					} else {
						this.$message.error(res.message)
					}
				})
			},
			//发表评论
			commentEvt() {
				if (!!this.comment) {
					if (!!this.commentValue) {
						this.$http.post('addComment', {
							courseId: this.id,
							comment: this.comment,
							evaluation: this.commentValue
						}).then(res => {
							const loading = this.$loading({
								lock: true,
								text: '发表评论中...',
								spinner: 'el-icon-loading',
								background: 'rgba(0, 0, 0, 0.7)'
							});
							if (res.code === 200) {
								this.comment = ''
								this.commentValue = 0
								this.$message.success('评论成功')
								this.commentListEvt()
							} else {
								this.$message.error(res.message)
							}
							loading.close()
						})
					} else {
						this.$message.error('请为当前评论打分')
					}
				} else {
					this.$message({
						type: 'error',
						message: '评论不能为空！',
						offset: 300,
					})
				}
			}

		}
	}
</script>

<style lang="less" scoped>
	.pageContainer {
		background: #fff;
	}
	.main {
		width: 1200px;
		margin: auto;
	}
	.content {
		margin-bottom: 65px;
		.left {
			width: 100%;
			box-shadow: 0px 3px 24px 0px rgba(0, 0, 0, 0.09);
			background-color: #EEEEEE;
			.left-top {
				width: 100%;
				height: 250px;
				padding: 50px;
				background-color: #fff;
				margin-bottom: 20px;
				.trainName{
					font-size: 30px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #333333;
				}
				.trainTime{
					display: inline-block;
					text-align: right;
					width: 67px;
					height: 20px;
					padding-right: 10px;
					line-height: 20px;
					font-size: 12px;
					font-family: PingFang SC;
					font-weight: 400;
					color: #FFFFFF;
					background-color: #39AEFF;
					margin-right: 10px;
					margin-bottom: 10px;
				}
				.trainTimeDetail{
					font-size: 14px;
					font-family: PingFang SC;
					font-weight: 400;
					color: #333333;
				}
				.trainCost{
					float: left;
					font-size: 30px;
					font-family: PingFang SC;
					font-weight: 500;
					color: #FF8839;
				}
				.tranpay{
					float: right;
					display: inline-block;
					width: 142px;
					height: 42px;
					background: #39AEFF;
					line-height: 42px;
					text-align: center;
					color: #fff;
					cursor: pointer;
				}
			}
			.left-bottom {
				min-height: 414px;
					padding: 0 10px;
				background-color: #fff;
				.trainHeader {
					display: flex;
				
					.trainHeaderItem {
						padding: 22px;
						font-size: 18px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						cursor: pointer;
						
					}
					.trainHeaderItemSelect{
						color: #39AEFF;
						border-top: 2px solid #39AEFF;
					}
				}
				.trainJianjie{
					padding: 0 40px;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 7;
					overflow: hidden;
					font-size: 16px;
					font-family: PingFang SC;
					font-weight: 400;
					color: #666666;
				}
				.tablemulu {
					.tableHeader{
						display: flex;
						background-color: #eee;
						height: 30px;
						font-size: 14px;
						font-family: PingFang SC;
						font-weight: bold;
						color: #333333;
						line-height: 30px;
						padding: 0 10px;
						.time {
							margin-right: 335px;
							margin-left: 50px;
						}
						.curName {
							margin-right: 456px;
						}
						.curLianxi {
							margin-right: 103px;
						}
						.color {
							color: #39AEFF;
							border-top: 1px solid #39AEFF;
						}
					}
					.tableBody {
						color: #999;
						font-size: 16px;
						cursor: pointer;
						
						.item {
							display: flex;
							padding: 0 10px;
							margin: 14px 0;
						
						.img {
							margin-top: 5px;
							width: 14px;
							height: 14px;
							background:url('../../assets/course/按钮.png') no-repeat;
							background-size: 100% 100%;
							margin-right: 15px;
								 vertical-align: middle;
						}
						.time {
							margin-right: 30px;
								 vertical-align: middle;
						}
						.curTime {
							margin-right: 40px
						}
						.name{
							width: 680px;
							overflow: hidden;
							text-overflow:ellipsis;
							white-space: nowrap;
							margin-right: 50px;
							// text-align: center;
						}
						.type{
							width: 50px;
							margin-right: 30px
						}
						.typetime{
							width: 50px;
							text-align: center;
							margin-right: 115px;
							img {
								width: 14px;
								height: 16px;
							}
						}
						}
						.item:hover {
							color: #39AEFF;
						}
						.item:hover .img{
								background:url('../../assets/course/play.png') no-repeat;
								background-size: 100% 100%;
						}
					}
				}
				.contentThree{
					padding: 0 30px;
					font-size: 14px;
					font-family: PingFang SC;
					font-weight: 500;
					color: #666666;
					padding-bottom: 50px;
					.pjia{
						margin-left: 20px;
						margin-top: 10px;
					}
					.buttonCls{
						line-height: 28px;
						height: 28px;
						margin-top: 10px;
						.btnpl{
							float: right;
							width: 66px;
							height: 28px;
							text-align: center;
							line-height: 28px;
							background: #39AEFF;
							border-radius: 4px;
							color: #fff;
							cursor: pointer;
						}
					}
					.allPing{
						width: 112px;
						height: 44px;
						text-align: center;
						line-height: 44px;
						border: 1px solid #FBAF25;
						border-radius: 4px;
						color: #FBAF25;
		
					}
					.pinglun {
					display: flex;
					margin-top: 20px;
						.touxiang {
							width: 46px;
							height: 46px;
							border-radius: 50%;
							margin-top: 15px;
							img {
								width: 100%;
								height: 100%;
								border-radius: 50%;
							}
						}
						p {
							// width: 1300px;
							font-size: 14px;
							font-family: PingFang SC;
							font-weight: 500;
							color: #333333;
							position: relative;
							span {
								margin-right: 15px;
							}
							.timeClass {
								position: absolute;
								right: 0;
								font-size: 14px;
								font-family: PingFang SC;
								font-weight: 500;
								color: #333333;
							}
						}
					}
					
				}
			}
		}
	}
</style>
